import { useSockets } from '@/app/composable/socket';
import { S } from '@/app/utilities';
import { AccessLevel } from '@/modules/access-policy/constants/access-levels.constants';
import { ExecutionStatusWrapper, ExecutionType, MessageType } from '@/modules/workflow-designer/constants';
import { EventMessage } from '@/modules/workflow-designer/types';
import { computed, Ref, ref } from '@vue/composition-api';
import * as R from 'ramda';
import {
    accessLevelOptions,
    calculationSchemeOptions,
    currencyOptions,
    expectedUsageOptions,
    licenseOptions,
    licenses,
    offlineRetentionOptions,
    reimbursementMethodOptions,
} from '../config/asset';
import { KerasStructure, MLLIbStructure, PMDArima, SKLearnStructure } from '../constants';
import { ModelAlgorithmLibrary } from '../types/model-algorithm-library.type';

export function useModelRegistration(asset: Ref<any>, readOnly: Ref<boolean> = ref(false)) {
    const accessLevel = ref<any>(null);
    const copyrightOwner = ref<any>(null);
    const {
        subscribe,
        unsubscribe,
        WebSocketsEvents,
        joinSocketRoom,
        leaveSocketRoom,
        WebSocketsRoomTypes,
    } = useSockets();

    const checkLicense = (license: any) => {
        if (license && license.label !== 'Custom') {
            const licenseMetadata = licenses.find((element) => element.license === license.label);
            if (licenseMetadata) {
                asset.value.metadata.license.license = licenseMetadata.license;
                asset.value.metadata.license.link = licenseMetadata.link;
                asset.value.metadata.license.expectedUsage = licenseMetadata.expectedUsage;
                asset.value.metadata.license.offlineRetention = licenseMetadata.offlineRetention;
            }
        } else if (license && license.label === 'Custom') {
            asset.value.metadata.license.license = 'Custom';
            asset.value.metadata.license.link = null;
            asset.value.metadata.license.expectedUsage = Object.keys(expectedUsageOptions);
            asset.value.metadata.license.offlineRetention = offlineRetentionOptions;
            asset.value.metadata.pricing.reimbursementMethod = [];
            asset.value.metadata.pricing.cost = null;
            asset.value.metadata.pricing.currency = 'EUR';
            asset.value.metadata.pricing.calculationScheme = null;
        }
    };

    const customLicense = computed(() => {
        if (
            asset.value &&
            asset.value.metadata &&
            asset.value.metadata.license &&
            (asset.value.metadata.license.license === undefined ||
                asset.value.metadata.license.license === null ||
                asset.value.metadata.license.license === 'Custom')
        ) {
            return true;
        }
        return false;
    });

    const getLicensingSchema = computed(() => {
        const schema: any = [];
        let items: any = null;
        if (asset.value.metadata.license) {
            items = [
                {
                    type: 'treeselect',
                    name: 'license',
                    label: 'Licence',
                    placeholder: 'Select licence',
                    clearable: false,
                    disableBranchNodes: true,
                    options: licenseOptions,
                    help:
                        'The legal statement/terms giving official permission to the asset in a custom manner or according to well-defined data licences.',
                    helpPosition: 'before',
                    validation: 'required',
                    labelClass: ['pb-1'],
                    disabled: accessLevel.value === AccessLevel.SelectiveSharing || readOnly.value,
                    input: () => {
                        //
                    },
                    select: checkLicense,
                    errorBehavior: 'value',
                },
                {
                    type: 'text',
                    name: 'link',
                    label: 'Link',
                    placeholder: 'Enter link',
                    help: 'A link to the exact legal terms of the specific licence.',
                    helpPosition: 'before',
                    validation: asset.value.metadata.license.link
                        ? 'bail|url|min:5,length|max:300,length|matches:/^[a-zA-Z0-9.:/-]+$/'
                        : null,
                    validationMessages: {
                        matches: 'Link can only contain letters, numbers, dots, dashes, slashes and colons.',
                    },
                    disabled: !customLicense.value || readOnly.value,
                    inputClass: 'form-input',
                    labelClass: ['pb-1'],
                    errorBehavior: 'value',
                },
                {
                    type: 'multiselect',
                    name: 'expectedUsage',
                    label: 'Expected Usage',
                    placeholder: 'Select expected usage',
                    options: expectedUsageOptions,
                    searchable: false,
                    multiple: true,
                    closeOnSelect: false,
                    clearOnSelect: false,
                    help:
                        'The specific data asset can be used for data analytics purposes in the following specified locations.',
                    helpPosition: 'before',
                    validation: 'required',
                    validationName: 'expected Usage',
                    customLabel: (expectedUsage: string) => expectedUsageOptions[expectedUsage],
                    disabled: accessLevel.value === AccessLevel.Public,
                    errorBehavior: 'submit',
                    labelClass: ['pb-1'],
                },
                {
                    type: 'checkbox',
                    name: 'offlineRetention',
                    label: 'Offline Retention',
                    options: offlineRetentionOptions,
                    help:
                        'An indication whether downloading outside the Energy Data Space (e.g. as a file or through an API) is permitted for the data asset.',
                    helpPosition: 'before',
                    disabled: accessLevel.value === AccessLevel.Public,
                    inputClass: 'inline-flex',
                },
            ];
            Array.prototype.push.apply(schema, items);
        }
        return schema;
    });

    const getPricingSchema = computed(() => {
        let schema: any = [];
        let items: any = null;
        if (asset.value?.metadata.pricing) {
            schema = [
                {
                    type: 'multiselect',
                    name: 'reimbursementMethod',
                    label: 'Reimbursement Method',
                    placeholder: 'Select reimbursement method',
                    options: reimbursementMethodOptions,
                    searchable: false,
                    multiple: true,
                    closeOnSelect: false,
                    clearOnSelect: false,
                    help: `The reimbursement method that the data provider has defined in order to acquire the data asset. Such a method ranges from a monetary transaction (i.e. the payment may be conducted “offline” - in respect to SYNERGIES - through a credit/debit card or a bank transfer, or “online” through crypto-currency in SYNERGIES), to a bartering/non-fiscal transaction (that offers the exchange of another data asset as compensation).`,
                    helpPosition: 'before',
                    validation: 'required',
                    validationName: 'reimbursement Method',
                    customLabel: (reimbursementMethod: string) => reimbursementMethodOptions[reimbursementMethod],
                    errorBehavior: 'submit',
                    labelClass: ['pb-2'],
                },
            ];

            if (
                asset.value?.metadata?.pricing?.reimbursementMethod?.includes('bank') ||
                asset.value?.metadata?.pricing?.reimbursementMethod?.includes('online')
            ) {
                items = [
                    {
                        type: 'select',
                        name: 'calculationScheme',
                        label: 'Calculation Scheme',
                        placeholder: 'Select calculation scheme',
                        options: Object.keys(calculationSchemeOptions).filter((key: string) => key !== 'Fixed per Row'),
                        help:
                            'The cost calculation scheme that is applied for the asset and may range from fixed per row and fixed per asset to request dependent.',
                        helpPosition: 'before',
                        validation: 'required',
                        errorBehavior: 'submit',
                        validationName: 'calculation Scheme',
                        inputClass: 'form-select',
                        labelClass: ['pb-2'],
                    },
                ];
                Array.prototype.push.apply(schema, items);
            }
            if (
                asset.value?.metadata.pricing.calculationScheme &&
                asset.value?.metadata.pricing.calculationScheme !== 'Request Dependent'
            ) {
                items = [
                    {
                        type: 'group',
                        name: 'costCurrency',
                        label: 'Cost',
                        help:
                            'The price for the acquisition of the data asset (as a whole or for each of its rows), including any value added tax and its currency.',
                        helpPosition: 'before',
                        children: [
                            {
                                component: 'div',
                                class: 'grid grid-cols-3 gap-5',
                                children: [
                                    {
                                        component: 'div',
                                        class: 'col-span-2',
                                        children: [
                                            {
                                                type: 'number',
                                                name: 'cost',
                                                placeholder: 'Enter cost',
                                                validation: 'bail|required|min:0',
                                                errorBehavior: 'submit',
                                                inputClass: 'form-input',
                                            },
                                        ],
                                    },
                                    {
                                        component: 'div',
                                        class: 'col-span-1',
                                        children: [
                                            {
                                                type: 'select',
                                                name: 'currency',
                                                placeholder: 'Select currency',
                                                options: currencyOptions,
                                                validation: 'required',
                                                errorBehavior: 'submit',
                                                inputClass: 'form-select',
                                                value: 'EUR',
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ];
                Array.prototype.push.apply(schema, items);
            }
        }

        return schema;
    });

    const getGeneralSchema = computed(() => {
        const items: any[] = [
            {
                type: 'multiselect',
                name: 'tags',
                label: 'Tags',
                placeholder: 'Add tags',
                options: [],
                multiple: true,
                closeOnSelect: false,
                clearOnSelect: false,
                preserveSearch: true,
                preselectFirst: false,
                taggable: true,
                help:
                    'A list of keywords and/or arbitrary textual tags associated with the asset by its data provider.',
                helpPosition: 'before',
                validation: 'bail|required|max:10|minTag:2|maxTag:50|alphanumericTag',
                validationRules: {
                    minTag: ({ value }: any) => value.every((tag: any) => tag.length >= 2),
                    maxTag: ({ value }: any) => value.every((tag: any) => tag.length <= 50),
                    alphanumericTag: ({ value }: any) =>
                        value.every((tag: any) => new RegExp(/^[a-zA-Z0-9 ]+$/).test(tag)),
                },
                validationMessages: {
                    minTag: 'Tags must be at least 2 characters long.',
                    maxTag: 'Tags must be less than or equal to 50 characters long.',
                    alphanumericTag: 'Tags can only contain letters, numbers and spaces.',
                },
                labelClass: ['pb-1'],
                errorBehavior: 'value',
            },
        ];

        return items;
    });

    const initAsset = (blocks: {
        general: boolean;
        distribution: boolean;
        extent: boolean;
        licensing: boolean;
        model: boolean;
        pricing: boolean;
    }) => {
        const emptyAsset: any = {
            name: null,
            description: null,
            status: null,
            metadata: {},
            policies: [],
            assetTypeId: 3,
        };

        if (blocks.general) {
            emptyAsset.metadata.general = {
                tags: [],
            };
        }

        if (blocks.distribution) {
            emptyAsset.metadata.distribution = { storage: 'cloud' };
        }

        if (blocks.licensing) {
            emptyAsset.metadata.license = {
                accessLevel: null,
                license: null,
                copyrightOwner: null,
                link: null,
            };
        }

        if (blocks.model) {
            emptyAsset.metadata.model = {
                library: '',
                type: '',
                purpose: '',
                name: '',
                algorithm: '',
                featureOrder: [],
                encodedFeatures: [],
            };
        }

        if (blocks.pricing) {
            emptyAsset.metadata.pricing = {
                cost: null,
                currency: 'EUR',
                reimbursementMethod: [],
                calculationScheme: null,
            };
        }

        return emptyAsset;
    };

    const modelStructure = ref<any>();

    const showModelValidationModal = ref<boolean>(false);
    const modelValidationStatus = ref<string>('');
    const modelValidationMessage = ref<string>();
    const canCloseModal = ref<boolean>(false);

    const librarySelected = async (event: any) => {
        switch (event) {
            case 'keras':
                modelStructure.value = KerasStructure;
                break;
            case 'mllib':
                modelStructure.value = MLLIbStructure;
                break;
            case 'sklearn':
            case 'xgboost':
            case 'statsmodel':
                modelStructure.value = SKLearnStructure;
                break;
            case 'pmdarima':
                modelStructure.value = PMDArima;
                break;
            default:
                modelStructure.value = [];
                break;
        }
    };

    const modalImage = computed(() => {
        switch (modelValidationStatus.value) {
            case 'uploading':
                return '/img/file_sync.svg';
            case 'running':
                return '/img/validating.svg';
            case 'completed':
                return '/img/success.svg';
            case 'failed':
            case 'invalid':
                return '/img/fail.svg';
            default:
                return '/img/validating.svg';
        }
    });

    const modalTitle = computed(() => {
        switch (modelValidationStatus.value) {
            case 'uploading':
                return 'Uploading model and sample data for validation';
            case 'running':
                return 'Validating model using sample data';
            case 'completed':
                return 'Model Validation Success!';
            case 'failed':
            case 'invalid':
                return 'Model Validation Failed!';
            default:
                return 'Validating model using sample data';
        }
    });

    const modalDescription = computed(() => {
        switch (modelValidationStatus.value) {
            case 'uploading':
                return 'Uploading model and sample data for validation. If the model provided is large, this may take a while. The sample file is cropped to the first 50 rows.';
            case 'running':
                return 'Model and sample data successfully uploaded. The model is now being validated by applying it to the sample data provided.';
            case 'completed':
                return 'Your model is successfully validated. You can fill-in the remaining forms and complete the registration!';
            case 'failed':
                return 'Your model could not be validated. Make sure you have filled in the correct model details, including model library, type, purspose and the model features in the order they were trained.';
            case 'invalid':
                return 'Your model directory structure is invalid. Make sure you follow the specified directory structure before zipping your model.';

            default:
                return 'Validating model using sample data';
        }
    });

    const modelSourceOptions = {
        Upload: 'Upload new trained model',
        Platform: 'Trained on the platform',
        OnPremise: 'Trained on-premise',
    };

    const modelLibraryOptions: Record<ModelAlgorithmLibrary, string> = {
        mllib: 'MLlib',
        sklearn: 'Scikit-learn',
        xgboost: 'XGBoost',
        statsmodel: 'Statsmodel',
        keras: 'Keras (Tensorflow)',
        pmdarima: 'Pmdarima',
    };

    const modelTypeOptions = computed(() => {
        if (asset.value && asset.value.metadata && asset.value.metadata.model) {
            if (asset.value.metadata.model.library === 'sklearn' || asset.value.metadata.model.library === 'mllib') {
                return {
                    model: 'Model',
                    transformer: 'Transformer (encoder/scaler)',
                    pipeline: 'Pipeline (transformers & model)',
                };
            }
            if (asset.value.metadata.model.library !== '') {
                return { model: 'Model' };
            }
        }
        return {};
    });

    const modelPurposeOptionsObject = {
        modelOrPipeline: {
            mllib: {
                classification: 'Classification',
                regression: 'Regression',
                clustering: 'Clustering',
            },
            sklearn: {
                classification: 'Classification',
                regression: 'Regression',
                clustering: 'Clustering',
                'unsupervised outlier detection': 'Outlier Detection',
            },
            xgboost: {
                classification: 'Classification',
                regression: 'Regression',
            },
            statsmodel: {
                'timeseries forecasting': 'Timeseries Analysis (arima, sarimax)',
                'timeseries forecasting var': 'Timeseries Analysis (VAR)',
            },
            keras: {
                'deep learning': 'Deep Learning',
            },
            pmdarima: {
                'timeseries forecasting': 'Timeseries Analysis',
            },
        },
        transformer: {
            mllib: {
                'One Hot Encoding': 'One Hot Encoding',
                'String Indexing': 'String Indexing',
                'MinMax Scaling': 'MinMax Scaling',
                'Standard Scaling': 'Standard Scaling',
                'Dimensionality Reduction': 'Dimensionality Reduction',
            },
            sklearn: {
                'One Hot Encoding': 'One Hot Encoding',
                'Label Encoding': 'Label Encoding',
                'Ordinal Encoding': 'Ordinal Encoding',
                'MinMax Scaling': 'MinMax Scaling',
                'Standard Scaling': 'Standard Scaling',
                'Dimensionality Reduction': 'Dimensionality Reduction',
            },
        },
    };

    const modelPurposeOptions = computed(() => {
        if (asset.value && asset.value.metadata && asset.value.metadata.model) {
            const type = asset.value.metadata.model.type === 'transformer' ? 'transformer' : 'modelOrPipeline';
            if (S.has(asset.value.metadata.model.library, modelPurposeOptionsObject[type])) {
                return modelPurposeOptionsObject[type][asset.value.metadata.model.library];
            }
        }
        return {};
    });

    const runningExecution = ref<string>();

    const onMessage = async (data: EventMessage) => {
        // Handle the case where we have an execution status change
        if (
            data.type === MessageType.Status &&
            Object.values(ExecutionType).includes(data.body.executionType as ExecutionType)
        ) {
            // Figure out if the currently running execution has completed
            if (!R.isNil(runningExecution.value) && !R.isNil(data.body.status)) {
                if (
                    data.executionId === runningExecution.value &&
                    ExecutionStatusWrapper.finishedStatuses().includes(data.body.status)
                ) {
                    modelValidationStatus.value = data.body.status;
                    modelValidationMessage.value = data.body.message;
                    canCloseModal.value = true;

                    unsubscribe(WebSocketsEvents.Workflow);
                    leaveSocketRoom(WebSocketsRoomTypes.Workflow, data.workflowId);
                }
            }
        }
    };

    const listenToWorkflow = (workflowId: string, executionId: string) => {
        runningExecution.value = executionId;
        subscribe(WebSocketsEvents.Workflow, (msg: any) => onMessage(msg));
        joinSocketRoom(WebSocketsRoomTypes.Workflow, workflowId);
    };

    return {
        asset,
        accessLevel,
        copyrightOwner,
        accessLevelOptions,
        customLicense,
        getLicensingSchema,
        initAsset,
        checkLicense,
        modelSourceOptions,
        modelTypeOptions,
        modelPurposeOptions,
        modelLibraryOptions,
        listenToWorkflow,
        showModelValidationModal,
        modelValidationStatus,
        canCloseModal,
        librarySelected,
        modalImage,
        modalTitle,
        modalDescription,
        modelStructure,
        getGeneralSchema,
        modelValidationMessage,
        getPricingSchema,
    };
}
